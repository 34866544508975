import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"--bs-bg-opacity":"0.5"},
  class: "alert alert-danger px-2 pt-3 pb-0"
}
const _hoisted_2 = { class: "mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.errors.length > 0)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode("ul", _hoisted_2, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.errors, (error) => {
            return (_openBlock(), _createBlock("li", {
              key: error.$uid
            }, [
              _createVNode("p", null, _toDisplayString(error.$message), 1)
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}