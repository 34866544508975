
import AppHeader from "@/components/AppHeader.vue";
import Error from "@/components/Error.vue";
import VDModal from "@/components/modals/VDModal";
import Page from "@/components/Page.vue";
import ResetPasswordModal from "@/components/modals/ResetPasswordModal.vue";
import OrganizationModal from "@/components/modals/OrganizationModal.vue";
import useError from "@/composables/useError";
import {
  OrganizationType,
  useGetOrganizationQuery,
  UserType,
} from "@/graphql/types";
import { useResult } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { FilterMatchMode } from "primevue/api";
import { defineComponent, ref, watch } from "vue";
import useUser from "@/composables/useUser";
import { useRoute } from "vue-router";

// Query
gql`
  query GetOrganization($organizationId: ID) {
    organization(organizationId: $organizationId) {
      name
      organizationId
      vat
      users {
        count
        items {
          roles {
            name
            application {
              name
            }
          }
          ...UserParts
        }
      }
    }
  }
`;

export default defineComponent({
  inheritAttrs: false,
  components: { AppHeader, Page, Error, OrganizationModal, ResetPasswordModal },
  name: "organization",
  props: {
    organizationId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const organizationModal = ref<VDModal<OrganizationType> | null>(null);
    const resetPasswordModal = ref<VDModal<UserType> | null>(null);
    const expandedRows = ref<UserType[]>([]);
    const route = useRoute();

    const { result, loading, error, refetch } = useGetOrganizationQuery(props, {
      fetchPolicy: "no-cache",
    });

    function onExpandClicked(data: UserType) {
      if (expandedRows.value.length > 0 && expandedRows.value[0] == data) {
        expandedRows.value = [];
      } else {
        expandedRows.value = [data];
      }
    }

    watch(
      () => route.params,
      async (newValue) => {
        const hasOnlyOrganizationId =
          Object.keys(newValue).length === 1 &&
          Object.prototype.hasOwnProperty.call(newValue, "organizationId");

        if (!hasOnlyOrganizationId) {
          return;
        }

        try {
          await refetch({
            organizationId: newValue.organizationId.toString(),
          });
        } catch (error) {
          console.error(error);
        }
      }
    );

    return {
      resetPasswordModal,
      organizationModal,
      organization: useResult(result),
      error: useError(error),
      loading,
      filters: useFilters(),
      onExpandClicked,
      expandedRows: expandedRows,
      user: useUser().user,
    };
  },
});

function useFilters() {
  return ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
}
