
import useConfirmService from "@/composables/useConfirmService";
import useError from "@/composables/useError";
import useToastService from "@/composables/useToastService";
import {
  GetOrganisationsDocument,
  GetOrganisationsQuery,
  OrganizationType,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
  GetOrganizationQueryVariables,
  GetOrganizationQuery,
  GetOrganizationDocument,
} from "@/graphql/types";
import { QueryOptions } from "@apollo/client/core";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import gql from "graphql-tag";
import { defineComponent, PropType, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import VDButton from "../VDButton.vue";

gql`
  mutation CreateOrganization($input: CreateOrganizationInputType!) {
    createOrganization(input: $input) {
      ...OrganizationParts
    }
  }
`;

gql`
  mutation DeleteOrganization($organizationId: ID!) {
    deleteOrganization(organizationId: $organizationId)
  }
`;

gql`
  mutation UpdateOrganization($input: UpdateOrganizationInputType!) {
    updateOrganization(input: $input) {
      ...OrganizationParts
    }
  }
`;

export default defineComponent({
  components: { VDButton },
  props: {
    organization: {
      type: Object as PropType<OrganizationType | null>,
      default: null,
    },
  },
  setup(props) {
    const confirmService = useConfirmService();
    const router = useRouter();
    const toastService = useToastService();

    const isVisible = ref(false);
    const newOrganization = reactive<OrganizationType>({ name: "", vat: "" });
    const v$ = useVuelidate(useValidations(), newOrganization);

    const deleteOrganizationMutation = useDeleteOrganizationMutation({});
    const createOrganizationMutation = useCreateOrganizationMutation({});
    const updateOrganizationMutation = useUpdateOrganizationMutation({});

    function deleteOrganization(event: Event) {
      function callMutation() {
        if (newOrganization.organizationId)
          deleteOrganizationMutation.mutate(
            { organizationId: newOrganization.organizationId },
            {
              refetchQueries: [{ query: GetOrganisationsDocument }],
            }
          );
      }

      confirmService.show(
        event,
        `Er du sikker? Handlingen kan ikke fortrydes`,
        "Ja, slet",
        "Afbryd",
        () => {
          callMutation();
        }
      );
    }

    function primaryButtonPressed() {
      v$.value.$touch();
      if (!v$.value.$invalid)
        if (props.organization && props.organization.organizationId) {
          updateOrganizationMutation.mutate(
            {
              input: {
                organizationId: newOrganization.organizationId,
                name: newOrganization.name,
                vat: newOrganization.vat,
              },
            },
            {
              refetchQueries: [{ query: GetOrganisationsDocument }],
              update: (cache, { data: newOrganization }) => {
                const parentQuery: QueryOptions<
                  GetOrganizationQueryVariables,
                  GetOrganizationQuery
                > = {
                  query: GetOrganizationDocument,
                  variables: {
                    organizationId: props.organization?.organizationId,
                  },
                };
                const cachedData = cache.readQuery(parentQuery);
                cache.writeQuery({
                  ...parentQuery,
                  data: {
                    organization: {
                      ...cachedData?.organization,
                      name: newOrganization?.updateOrganization?.name,
                      vat: newOrganization?.updateOrganization?.vat,
                    },
                  },
                });
              },
            }
          );
        } else {
          createOrganizationMutation.mutate(
            {
              input: {
                name: newOrganization.name,
                vat: newOrganization.vat,
              },
            },
            {
              update: (cache, { data: newOrganization }) => {
                const parentQuery: QueryOptions<
                  undefined,
                  GetOrganisationsQuery
                > = {
                  query: GetOrganisationsDocument,
                };

                const cachedData = cache.readQuery(parentQuery);
                cache.writeQuery({
                  ...parentQuery,
                  data: {
                    organizations: {
                      ...cachedData?.organizations,
                      count: (cachedData?.organizations?.count ?? 1) + 1,
                      items: [
                        ...(cachedData?.organizations?.items ?? []),
                        newOrganization?.createOrganization ?? null,
                      ],
                    },
                  },
                });
              },
            }
          );
        }
    }

    createOrganizationMutation.onDone((result) => {
      v$.value.$reset();
      Object.assign(newOrganization, { name: "", vat: "", organizationId: "" });
      isVisible.value = false;

      toastService.show({
        severity: "success",
        title: "Organisation oprettet",
        message: `Den har fået ID: ${result.data?.createOrganization?.organizationId}`,
      });
    });

    updateOrganizationMutation.onDone(() => {
      toastService.show({
        severity: "success",
        title: "Organisationen opdateret",
        message: "Dine ændringer er blevet gemt",
      });
      isVisible.value = false;
    });

    deleteOrganizationMutation.onDone(() => {
      toastService.show({
        severity: "success",
        title: "Organisation slettet",
        message: `Organisationen er blevet slettet`,
      });
      router.push("/organizations");
    });

    createOrganizationMutation.onError((error) =>
      toastService.show({ severity: "error", error: useError(error) })
    );
    updateOrganizationMutation.onError((error) =>
      toastService.show({ severity: "error", error: useError(error) })
    );
    deleteOrganizationMutation.onError((error) =>
      toastService.show({ severity: "error", error: useError(error) })
    );

    watch(
      () => props.organization,
      (newValue) => {
        Object.assign(newOrganization, newValue);
      },
      { immediate: true }
    );

    return {
      isVisible,
      show: () => (isVisible.value = true),
      hide: () => (isVisible.value = false),
      newOrganization,
      v$,
      deleteOrganizationMutation,
      updateOrganizationMutation,
      createOrganizationMutation,
      primaryButtonPressed,
      deleteOrganization,
    };
  },
});

function useValidations() {
  return {
    name: { required },
  };
}

export declare class OrganizationModal {
  show(): void;
  hide(): void;
}
