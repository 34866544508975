
import { ErrorObject } from "@vuelidate/core";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    errors: {
      type: Array as PropType<ErrorObject[]>,
      default: () => [],
    },
  },
});
