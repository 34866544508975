
import useError from "@/composables/useError";
import usePasswordValidation from "@/composables/usePasswordValidation";
import useRandomPassword from "@/composables/useRandomPassword";
import useToastService from "@/composables/useToastService";
import { useResetUserPasswordMutation, UserType } from "@/graphql/types";
import useVuelidate from "@vuelidate/core";
import { helpers, sameAs } from "@vuelidate/validators";
import gql from "graphql-tag";
import { defineComponent, reactive, Ref, ref, toRef } from "vue";
import VDValidationErrors from "../VDValidationErrors.vue";

gql`
  mutation ResetUserPassword($input: ChangeUserPasswordInputType!) {
    changeUserPassword(input: $input)
  }
`;

interface ResetPasswodInput {
  newPassword: string;
  newPasswordRepeated: string;
}

export default defineComponent({
  components: { VDValidationErrors },
  setup() {
    const toastService = useToastService();

    const user = ref<UserType | null>(null);
    const resetPasswordInput = reactive<ResetPasswodInput>({
      newPassword: "",
      newPasswordRepeated: "",
    });

    const isVisible = ref(false);
    const v$ = useVuelidate(
      useValidations(toRef(resetPasswordInput, "newPassword")),
      resetPasswordInput
    );

    const resetUserPasswordMutation = useResetUserPasswordMutation({});

    function primaryButtonPressed() {
      v$.value.$touch();
      if (!v$.value.$error) {
        resetUserPasswordMutation.mutate({
          input: {
            password: resetPasswordInput.newPassword,
            userId: user.value?.userId,
          },
        });
      }
    }

    function reset() {
      v$.value.$reset();
      Object.assign(resetPasswordInput, {
        newPassword: "",
        newPasswordRepeated: "",
      });
      isVisible.value = false;
      user.value = null;
    }

    resetUserPasswordMutation.onDone(() => {
      toastService.show({
        severity: "success",
        title: "Brugernavn nulstillet",
        message: `Brugeren ${user.value?.email} har fået nulstillet sit kodeord`,
      });
      reset();
    });
    resetUserPasswordMutation.onError((error) =>
      toastService.show({ severity: "error", error: useError(error) })
    );

    return {
      isVisible,
      user,
      show: (vUser: UserType) => {
        user.value = vUser;
        isVisible.value = true;
        const newPassword = useRandomPassword();
        resetPasswordInput.newPassword = newPassword;
        resetPasswordInput.newPasswordRepeated = newPassword;
      },
      hide: () => reset(),
      primaryButtonPressed,
      resetPasswordInput,
      v$,
      resetUserPasswordMutation,
    };
  },
});

function useValidations(newPassword: Ref<string>) {
  return {
    newPassword: usePasswordValidation(),
    newPasswordRepeated: {
      sameAsPassword: helpers.withMessage(
        "Kodeorderne matcher ikke",
        sameAs(newPassword)
      ),
    },
  };
}
